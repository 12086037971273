<template>
  <questions-view />
</template>

<script>
  import QuestionsView from '../components/Questions'

  export default {
    name: 'Questions',
    components: {
      QuestionsView,
    },
  }
</script>
