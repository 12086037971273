<template>
  <v-container>
    <v-row v-if="loading" align="center" class="" justify="center">
      <v-progress-circular indeterminate color="fo"></v-progress-circular>
    </v-row>
    <v-row v-if="!loading">
      <v-col v-for="tag in tags.data" :key="tag.id" cols="12">
        <router-link :to="`/tags/${tag.id}`">
          <v-card class="mx-auto" elevation="3">
            <v-list-item>
              <v-list-item-avatar color="grey">
                <v-icon dark> mdi-tag </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ tag.name }}</v-list-item-title>
                <v-list-item-title> # {{ tag.count }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
    <div v-if="!loading" class="text-center my-5">
      <v-pagination
        v-model="page"
        :length="tags.max_page"
        :total-visible="8"
        @input="loadTags()"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Tags",
  data: () => ({
    page: 1,
  }),
  mounted() {
    this.loadTags();
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    tags() {
      return this.$store.getters.tags;
    },
  },

  methods: {
    loadTags() {
      this.$store.dispatch("loadTags", {
        tab: 3,
        page: this.page,
      });
    },
  },
};
</script>

   <style scoped>
   a{
     text-decoration: none;
   }
   </style>

