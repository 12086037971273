<template>
  <posts-view />
</template>

<script>
  import PostsView from '../components/Posts'

  export default {
    name: 'Posts',
    components: {
      PostsView,
    },
  }
</script>
