<template>
  <tags-view />
</template>

<script>
  import TagsView from '../components/Tags'

  export default {
    name: 'Tags',
    components: {
      TagsView,
    },
  }
</script>
